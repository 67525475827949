/* eslint jsx-a11y/img-redundant-alt: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useContext } from "react";
import ReactPlayer from "react-player/lazy";
import CategoryIcon from "../images/icon_categories.png";
import ServiceIcon from "../images/icon_services.png";
import VerifiedIcon from "../images/icon_varified.png";
import Sherry from "../images/Sherry.png";
import John from "../images/hartz.png";
import Monica from "../images/Monica.png";
import Brett from "../images/Brett_Holly.png";
import Scott from "../images/scott.jpeg";
import Neely from "../images/Neely.png";
import Pete from "../images/Pete.jpeg";
import Rebecca from "../images/Rebecca.jpeg";
import Slider from "react-slick";
import { Link } from "gatsby";
import SearchForm from "./searchForm";
import Ads from "./ads";
import returnWPUrl from "./settings.json";
import setCityAndState from "../helper/setCityAndState";
import { GlobalStateContext } from "../store/GlobalContextProvider";
const axios = require("axios");

export default function Home() {
  const [featured, setFeatured] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [city_name, setCityName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [state_code, setStateCode] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const state = useContext(GlobalStateContext);
  useEffect(() => {
    setCityAndState(setCityName, setStateCode);
    setLat(localStorage.getItem("lat"));
    setLng(localStorage.getItem("lng"));
  }, [state?.locationToggle]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      ignore = true;
      getFeaturedCategories();
    }
  }, []);

  var settings1 = {
    arrows: true,
    autoplay: true,
    dots: true,
    autoplaySpeed: 9000,
    speed: 1000,
    slidesToShow: 3,
    centerMode: true,
    tabMenu: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const getFeaturedCategories = () => {
    axios({
      url: returnWPUrl.apiURL,
      method: "post",
      data: {
        query: `{
          getFeaturedCategories {
              icon
              id
              name
              slug
              subcategories {
                category_id
                id
                name
                slug
              }
            }
          }`,
      },
    })
      .then((res) => {
        setFeatured(res.data.data.getFeaturedCategories);
        return "success";
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div style={{ zIndex: `1` }}>
      <main>
        <SearchForm />

        <div id="homeAdvIframe">
          <Ads zoneid={69} />
        </div>
        <section className="container categories category-section">
          <p className="section-head">Popular Categories</p>
          <div className="row category-row">
            {featured.slice(0, 12).map((item, i) => {
              return (
                <Link
                  style={{ textDecoration: `none` }}
                  className="col-lg-2 col-md-4 col-6 category-type"
                  to={
                    city_name !== "" && state_code !== ""
                      ? lat !== "" && lng !== ""
                        ? "/c/" +
                          item.slug +
                          "/" +
                          city_name +
                          "-" +
                          state_code +
                          "?" +
                          lat +
                          "-" +
                          lng
                        : "/c/" + item.slug + "/" + city_name + "-" + state_code
                      : "/c/" + item.slug
                  }
                  key={`link${i}`}
                >
                  <img
                    style={{ objectFit: `contain` }}
                    src={item.icon}
                    alt="categories"
                  />
                  {item.name}
                </Link>
              );
            })}
          </div>
          <div className="d-flex justify-content-center">
            <Link to="/categories/" role="button" className="btn">
              SEE MORE
            </Link>
          </div>
        </section>
        <section className="container promise-section">
          <div className="container">
            <p className="section-head">
              Why choose Christian Blue? Because our promise to you is that
              every advertiser commits to...
            </p>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-12 proclaim-column">
                <div className="d-flex flex-start">
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSItNzYgMCA1MTIgNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0yMjUgNTEydi0zMDFoMTM1di05MGgtMTM1di0xMjFoLTkwdjEyMWgtMTM1djkwaDEzNXYzMDF6bTAgMCIvPjwvc3ZnPg=="
                    alt="Proclaim"
                    className="promise-icon"
                  />
                  <div className="promise-tab">
                    <p className="bible-head">Proclaim</p>
                    <p className="bible-saying">
                      Jesus Christ as their Lord & Savior
                    </p>
                    <p className="bible-verse">ROMANS 10:9</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 demonstrate-column">
                <div className="d-flex flex-start">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGc+DQoJCTxwYXRoIGQ9Ik0yNzEuNSwxMjkuMzkxVjYwSDMwMVYzMGgtMjkuNVYwaC0zMHYzMEgyMTF2MzBoMzAuNXY2OC41NThMNjEsMjc4Ljk3NFY1MTJoMTcwVjM0MmMwLTEzLjc4NSwxMS4yMTUtMjUsMjUtMjUNCgkJCWMxMy43ODUsMCwyNSwxMS4yMTUsMjUsMjV2MTcwaDE3MFYyNzguOTc0TDI3MS41LDEyOS4zOTF6IE0xNjYsNDUyaC0zMFYzMTdoMzBWNDUyeiBNMjU2LDI2MWMtMTEuMDQ2LDAtMjAtOC45NTQtMjAtMjANCgkJCXM4Ljk1NC0yMCwyMC0yMHMyMCw4Ljk1NCwyMCwyMFMyNjcuMDQ2LDI2MSwyNTYsMjYxeiBNMzc2LDQ1MmgtMzBWMzE3aDMwVjQ1MnoiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg=="
                    alt="Demonstrate"
                    className="promise-icon"
                  />
                  <div className="promise-tab">
                    <p className="bible-head">Demonstrate</p>
                    <p className="bible-saying">
                      Their faith through active involvement in a local church
                    </p>
                    <p className="bible-verse">Hebrews 10:25</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 strive-column">
                <div className="d-flex flex-start">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDIwMC40MDEgMjAwLjQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjAwLjQwMSAyMDAuNDAxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNMTY5LjYyNCwxNzUuMjg4YzQuMTQzLDAsNy41LTMuMzU4LDcuNS03LjVWNy41YzAtNC4xNDItMy4zNTctNy41LTcuNS03LjVIMzguMjQyQzI5Ljk5LDAsMjMuMjc3LDYuNzEzLDIzLjI3NywxNC45NjUNCgl2MTU4LjI1MmMwLDAuMjU2LDAuMDEzLDAuNTEsMC4wMzgsMC43NmMtMC4wMTMsMC4yNTItMC4wMzgsMC41MDEtMC4wMzgsMC43NTd2MTAuNTc3YzAsOC4zMjEsNi43NywxNS4wOTEsMTUuMDkxLDE1LjA5MWgyLjEwOA0KCWgxMjEuMjQ1aDcuOTAzYzQuMTQzLDAsNy41LTMuMzU4LDcuNS03LjVzLTMuMzU3LTcuNS03LjUtNy41aC0wLjQwM3YtMTAuMTEzSDE2OS42MjR6IE03Ny44NjcsNTkuMDY3SDkyLjdWNDQuNTYzDQoJYzAtNC4xNDIsMy4zNTctNy41LDcuNS03LjVzNy41LDMuMzU4LDcuNSw3LjV2MTQuNTA1aDE0LjgzM2M0LjE0MywwLDcuNSwzLjM1OCw3LjUsNy41cy0zLjM1Nyw3LjUtNy41LDcuNUgxMDcuN3Y0NS40OTUNCgljMCw0LjE0Mi0zLjM1Nyw3LjUtNy41LDcuNXMtNy41LTMuMzU4LTcuNS03LjVWNzQuMDY3SDc3Ljg2N2MtNC4xNDMsMC03LjUtMy4zNTgtNy41LTcuNVM3My43MjUsNTkuMDY3LDc3Ljg2Nyw1OS4wNjd6DQoJIE0xNTQuMjIxLDE4NS40MDFINDAuNDc2aC0yLjEwOGMtMC4wNSwwLTAuMDkxLTAuMDQxLTAuMDkxLTAuMDkxdi0xMC4wMjJoMTE1Ljk0NFYxODUuNDAxeiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo="
                    alt="Strive"
                    className="promise-icon"
                  />
                  <div className="promise-tab">
                    <p className="bible-head">Strive</p>
                    <p className="bible-saying">
                      Strive for excellence in business for the glory of God
                    </p>
                    <p className="bible-verse">Colossians 3:23</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 strive-column">
                <div className="d-flex flex-start strive-new-tab">
                  <div className="promise-tab">
                    <p className="bible-saying">
                      Do you see a man skillful in his work? He will stand
                      before kings; he will not stand before obscure men.
                    </p>
                    <p className="bible-verse">Proverbs 22:29</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center promise-img">
              <img
                src="https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="People holding wrists to form a square"
              />
            </div>
          </div>
        </section>
        <section className="container-fluid video-section">
          <div className="row">
            <div className="col-lg-6 col-sm-12 videoplayer">
              {
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=ZUuc_NoS5Ag&t=1s"
                  controls={true}
                  fallback="https://www.youtube.com/watch?v=ZUuc_NoS5Ag&t=1s"
                  autoPlay={false}
                  loop={false}
                  className="videoIframe"
                />
              }
            </div>

            <div className="col-lg-6 col-sm-12 about-president">
              <p className="section-head text-left">Darrel Geis</p>
              <p className="president">PRESIDENT</p>
              <p className="video-section-desc">
                It's harder than ever these days to know which businesses to
                trust. This can leave you feeling overwhelmed and uneasy about
                where to turn. At Christian Blue, we find and certify businesses
                as Christian-owned and operated in your community. That way,
                when you are looking to shop, you can be sure you're getting the
                quality products and services you need while supporting
                companies that share your values. Support Certified Christian
                Businesses...we found them for you here at ChristianBlue.com,
                the Christian Blue Mobile app, and in the Christian Blue Pages
                print directory.
              </p>
              {/* <p className="video-section-desc">
                When you have a product or service need, choose Christian Blue
                , and get it
                <span className="out-of-the-blue">“Out of the Blue!“</span>
              </p> */}
            </div>
          </div>
        </section>
        <section className="container how-it-works-section">
          <div className="row">
            <div className="col-md-6 how-it-works-section-card">
              <p className="section-head how-it-work text-left">
                How our website works
              </p>
              <p className="its-insanely-easy-to-get-start">
                It's insanely easy to get started
              </p>
              <p className="how-it-works-desc"></p>
              <div className="d-flex">
                <a href={returnWPUrl.wpUrl + "help-us-connect/"}>
                  <button type="button" className="btn">
                    START NOW!
                  </button>
                </a>
              </div>
            </div>
            <div className="col-md-6 how-it-works-section-card">
              <div className="process">
                <div className="process-section">
                  <div className="head">Choose</div>
                  <p>
                    Choose to support a certified Christian business for your
                    product or service need.
                  </p>
                </div>
                <div className="process-section">
                  <div className="head">Search</div>
                  <p>
                    Confirm the desired location of your search, then type in
                    business category or name.
                  </p>
                </div>
                <div className="process-section">
                  <div className="head">Contact</div>
                  <p>
                    Please let the business know that you found them in
                    Christian Blue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid why-choose-us-section">
          {/* <p className="section-head">Why Choose Us?</p> */}
          <p className="section-head">Why choose Christian Blue?</p>
          <div className="row">
            <div className="col-md-12 counter-container">
              <div className="">
                <span
                  style={{
                    background: `#195D9D`,
                    padding: `26px`,
                    borderRadius: `50%`,
                    position: `relative`,
                    right: `100px`,
                    top: `75px`,
                    height: `85px`,
                    width: `85px`,
                  }}
                >
                  <img src={CategoryIcon} alt="categories" />
                </span>
                <span className="counter counter-head" data-target="100">
                  250+
                </span>
                <span>Categories</span>
              </div>
              <div>
                <span
                  style={{
                    background: `#195D9D`,
                    padding: `26px`,
                    borderRadius: `50%`,
                    position: `relative`,
                    right: `100px`,
                    top: `75px`,
                    height: `85px`,
                    width: `85px`,
                  }}
                >
                  <img src={ServiceIcon} alt="serviceIcon" />
                </span>
                <span className="counter counter-head" data-target="15000">
                  30+ Years
                </span>
                <span>Serving you</span>
              </div>
              <div>
                <span
                  style={{
                    background: `#195D9D`,
                    padding: `26px`,
                    borderRadius: `50%`,
                    position: `relative`,
                    right: `100px`,
                    top: `75px`,
                    height: `85px`,
                    width: `85px`,
                  }}
                >
                  <img src={VerifiedIcon} alt="verification" />
                </span>
                <span className="counter counter-head">Certified</span>
                <span>Christian Businesses</span>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid testimonial-section">
          <p className="section-head">Faith Testimonies Of Our Clients</p>
          <p className="section-subhead text-center">
            How does your faith in Jesus impact the way you do business?
          </p>
          <div className="testimonials">
            <Slider {...settings1}>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={Scott}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  Living as a Christians in a relationship with Christ holds us
                  to a higher standard in business when it comes to integrity
                  and character. Our relationship with Christ is an
                  understanding that the business no longer belongs to us but it
                  belongs to the Lord, and we serve Him and his commandments in
                  every aspect of the daily duties of running a shop. So with
                  that in mind, we never hide that we’re a Christian owned shop,
                  and you never know who comes into the shop. Employees also
                  watch you, and we want them to see something different so that
                  they might want to learn more about what’s different and learn
                  about Christ’s love.
                </p>
                <p className="reviewer">Scott & Cindy Davidson</p>
                <p className="reviewer-title">Davidson Garage</p>
              </div>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={Neely}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  My faith is the foundation upon the which I live my life and
                  conduct my business. I try to treat others the way Christ has
                  always treated me, with mercy, grace and love. This includes
                  my family, my co-workers and my patients
                </p>
                <p className="reviewer">Dr. Matthew Neely</p>
                <p className="reviewer-title">Neely Dental Team</p>
              </div>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={Pete}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  Simple. The Lord has shown me through His awesome power how to
                  serve others. My faith in Christ has made that a pretty easy
                  task. Put other first in your life, and at work, that means my
                  customers!
                </p>
                <p className="reviewer">Pete Esposito</p>
                <p className="reviewer-title">Integrity Automotive Service</p>
              </div>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={Brett}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  It affects everything we do, because Jesus is the center of
                  our lives. It affects how we deal with customers, train our
                  employees, and how we interact with our community. Our faith
                  is a part of every decision we make. It is our hope that
                  people leave our store feeling that something is different
                  about our business. The difference is Jesus.
                </p>
                <p className="reviewer">Brett & Holly Neely</p>
                <p className="reviewer-title">Coldstone Creamery</p>
              </div>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={Monica}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  I am bold with my profession and Love of the Lord. The
                  patients may be scheduled for dental treatment but then will
                  confide in me on a personal level. I have no reservation to
                  lay hands on my patients and pray over them when the need
                  arises.
                </p>
                <p className="reviewer">Dr. Monica Swope</p>
                <p className="reviewer-title">Kingdom Family Dentistry</p>
              </div>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={John}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  Being Christian allows us to engage both our employees and our
                  customers alike on a deeper level than just as a business
                  transaction. We care about what is going on in their lives and
                  listening to them helps us to meet more than just their
                  surface needs. They know we care about them as a person and
                  our more interested in the relationship we develop with them
                  than getting what we want out of them.
                </p>
                <p className="reviewer">John Hartz</p>
                <p className="reviewer-title">Hartz Sealcoating</p>
              </div>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={Rebecca}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  Being a Christian affects every area. I turned my practice
                  over to Jesus from the beginning, so he’s the CEO and I follow
                  his lead.
                </p>
                <p className="reviewer">Rebecca J. Allf</p>
                <p className="reviewer-title">Attorney at Law</p>
              </div>
              <div className="testimonial-card">
                <img
                  className="card-img-top"
                  src={Sherry}
                  alt="Card image cap"
                  width="85px"
                  height="100px"
                />
                <i className="fa fa-quote-left"></i>
                <p className="review">
                  I do everything in my life with the thought, “would this
                  please God?” I don’t always succeed but I try my best with the
                  Lord’s help. I am diligent in my work and strive to provide
                  the best possible service to my clients so that they can see
                  Jesus in me.
                </p>
                <p className="reviewer">Sherry Ellington, Owner</p>
                <p className="reviewer-title">
                  Owner, Freedom Bookkeeping Services
                </p>
              </div>
            </Slider>
          </div>
        </section>
        <section className="container-fluid get-the-app">
          <p className="section-head">Get the Christian Blue App</p>
          <p className="get-the-app-tag text-center">
            Connect with Christian businesses 24/7/365 wherever you are!
          </p>
          <p className="get-the-app-tag">
            <ul>
              <li>
                Geo-located search results for your product & service needs
                <br />
              </li>
              <li>Notifications and Alerts - new & featured businesses</li>
              <li>Mobile Deals for Your Family</li>
            </ul>
          </p>
          <div className="app-badges justify-content-center">
            <Link to="/mobile-app" role="button" className="btn">
              Download Now
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
}
