/* eslint no-self-assign: 0 */
import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../store/GlobalContextProvider";
const defaultPincode = "45231";

export default function Ads({ zoneid, marginAdv }) {
  const [zip, setZip] = useState(defaultPincode);
  const [refreshCount, setRefreshCount] = useState(0);
  var bust = Math.floor(89999999 * Math.random() + 10000000);
  var millis = new Date().getTime();
  const [source, setSource] = useState(
    `https://christianbluepages.advertserve.com/servlet/view/banner/unique/url/strip?zid=${zoneid}&pid=0&total=3&layout=horizontal&margin=0&padding=6&random=${bust}&millis=${millis}&custom1=${zip}`
  );
  const state = useContext(GlobalStateContext);

  useEffect(() => {
    if (localStorage.getItem("new_zipcode") !== undefined) {
      setZip(localStorage.getItem("new_zipcode"));
    } else {
      setZip(defaultPincode);
    }
    reloadIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var handle = setInterval(reloadIframe, 10000);

    return () => {
      clearInterval(handle);
    };
  }, [zip]);

  // render the ads container onto the DOM wiht the change in the src
  // just updating the src of the iFrame adds it to the history
  // !! to fix the history issue, replacing the container instead of just "src"
  const renderAds = () => {
    return (
      <div
        id="homeBanners"
        style={{ marginTop: 10 }}
        className="ads d-flex flex-wrap align-items-center justify-content-center"
      >
        <iframe
          key={`id${refreshCount}`}
          id="homebanners_iframe"
          title="advertserve"
          src={source}
          style={{
            margin: marginAdv ? marginAdv : "0 20%",
            padding: `0`,
            border: `none`,
          }}
          width="98% !IMPORTANT"
          className={"iframeAdv" + zoneid}
          height="250px"
          scrolling="no"
        />
      </div>
    );
  };

  // trigger change in the source of the ads URL
  // to refresh the ads after a certain time duration
  const reloadIframe = () => {
    if (state.tab === "active") {
      var reload = document.getElementById("homebanners_iframe");
      if (
        localStorage.getItem("new_zipcode") !== undefined &&
        localStorage.getItem("new_zipcode") !== zip
      ) {
        var src = reload.src.replace(
          `custom1=${zip}`,
          `custom1=${localStorage.getItem("new_zipcode")}`
        );
        setZip(localStorage.getItem("new_zipcode"));
        // set the count to 0
        setRefreshCount(0);

        // add the key to the url to make updates and making it unique
        // might not be very relivent but making sure the source is unique every time
        setSource(`${src}?${0}`);
      } else {
        let newSource = source.replace(
          `?${refreshCount}`,
          `?${refreshCount + 1}`
        );
        // see if the key to the url is added
        // if not just add it
        if (source.indexOf(`?${refreshCount}`) < 0) {
          newSource = `${source}?${refreshCount + 1}`;
        }

        setSource(newSource);
      }

      // increase the refresh count
      setRefreshCount(count => count + 1);
    }
  };

  return (
    <section className="container-fluid horizontal-ads-section">
      {renderAds()}
    </section>
  );
}
