/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { useState, useEffect } from "react";
import FormInner from "./forminner";
import settings from "./settings.json";
const axios = require("axios");

const SearchForm = () => {
  const [banner, setBanner] = useState([]);
  const [displaySearch, setDisplaySearch] = useState("block");
  const [displayLocation, setDisplayLocation] = useState("block");

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      ignore = true;
      getBanner();
    }
  }, []);

  const getBanner = () => {
    axios({
      url: settings.apiURL,
      method: "post",
      data: {
        query: `{
                    getBanner{
                        id
                        banner
                    }
                }`,
      },
    })
      .then((res) => {
        setBanner(res.data.data.getBanner[0].banner);
        return "success";
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <section
        onClick={() => {
          if (displaySearch === "block") {
            setDisplaySearch("none");
          }
          if (displayLocation === "block") {
            setDisplayLocation("none");
          }
        }}
        className="header-main section-header"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container-fluid home-banner-contant">
          <h1 className="header-main-heading">
            Search For Christian Businesses
          </h1>
          <p style={{ color: `#4D4D4D` }} className="header-main-subheading">
            Support Certified Christian Businesses - we found them for you
          </p>
          <div className="header-main-search-group d-md-flex flex-wrap">
            <FormInner />
          </div>
        </div>
      </section>
    </div>
  );
};

export default SearchForm;
