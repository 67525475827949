import React from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import Home from "../components/home";
import SeO from "../components/SEO";

export default function Index() {
  return (
    <div>
      <SeO title={`Christian Business Directory`} defer={true} />
      <Header />
      <Home />
      <Footer />
    </div>
  );
}
